import React from "react";
import "./card.css";
import { useLocation, useNavigate } from "react-router-dom";

const ResultPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { resultData } = location.state || {};


  return (
    <div>
      <div className="card">
        <div className="card-header" style={{ textAlign: "center" }}>
          <img
            className="signature-image"
            src="https://result.7college.du.ac.bd/rokey/logo.png"
            alt="University Logo"
            style={{ display: "block", margin: "0 auto" }}
          />
          <h2 className="header-large">UNIVERSITY OF DHAKA</h2>
          <h3 className="header-medium">𝐀𝐟𝐟𝐢𝐥𝐢𝐚𝐭𝐞𝐝 7 𝐂𝐨𝐥𝐥𝐞𝐠𝐞s</h3>
          <p className="text-normal">
            Department of {resultData.sub_name}
          </p>
          <p className="text-normal">{resultData.exam_title}</p>
        </div>

        <div className="placeholder">
            Online Result Sheet
        </div>


        <div >
          <table className="student-info">
            <tbody>
              <tr>
                <td>Name</td>
                <td>{resultData.name}</td>
              </tr>
              <tr>
                <td>College</td>
                <td>{resultData.college_name}</td>
              </tr>
              <tr>
                <td>Roll No</td>
                <td>{resultData.roll}</td>
              </tr>
              <tr>
                <td>Registration</td>
                <td>{resultData.reg}</td>
              </tr>
              <tr>
                <td>Session</td>
                <td>{resultData.session_name}</td>
              </tr>
              <tr>
                <td>Student Type</td>
                <td>{resultData.type_name}</td>
              </tr>
              {resultData.first_gpa && (
                <tr>
                  <td>1st Year GPA</td>
                  <td>{resultData.first_gpa}</td>
                </tr>
              )}
              {resultData.second_gpa && (
                <tr>
                  <td>2nd Year GPA</td>
                  <td>{resultData.second_gpa}</td>
                </tr>
              )}
              {resultData.third_gpa && (
                <tr>
                  <td>3rd Year GPA</td>
                  <td>{resultData.third_gpa}</td>
                </tr>
              )}
              {resultData.fourth_gpa && (
                <tr>
                  <td>4th Year GPA</td>
                  <td>{resultData.fourth_gpa}</td>
                </tr>
              )}
              {resultData.cgpa && (
                <tr>
                  <td>Final CGPA</td>
                  <td>{resultData.cgpa}</td>
                </tr>
              )}
              <tr>
                <td>Result</td>
                <td>{resultData.result}</td>
              </tr>
            </tbody>
          </table>
          <table className="grades black-table">
            <thead>
              <tr>
                <th>Paper Code</th>
                <th>Paper Title</th>
                <th>Letter Grade</th>
                <th>Grade Point</th>
                <th>Credit</th>
              </tr>
            </thead>
            <tbody>
              {resultData.courses.map((course, index) => (
                <tr key={index}>
                  <td>{course.code}</td>
                  <td style={{ paddingRight: "5px", textAlign: "left" }}>{course.title}</td>
                  <td>{course.lg}</td>
                  <td>{course.gp}</td>
                  <td>{course.credit}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <tfoot>
              <tr>
                <td >
                  <p style={{marginTop:-5}}>
                    Result Publication Date: {resultData.pdate}
                  </p>
                </td>
              </tr>
            </tfoot>
        </div>
        <div className="signature-text">
          <img
            className="signature-image-right" 
            src="https://result.7college.du.ac.bd/rokey/sign.png"
            alt="Signature"
          />
          <div >
            <h3 style={{ margin: "5px 0", fontSize: 19 }}>𝐌𝐝. 𝐁𝐚𝐡𝐚𝐥𝐮𝐥 𝐇𝐨𝐪𝐮𝐞 𝐂𝐡𝐨𝐰𝐝𝐡𝐮𝐫𝐲</h3>
            <p style={{ margin: "5px 0", fontSize: 18 }}>𝘊𝘰𝘯𝘵𝘳𝘰𝘭𝘭𝘦𝘳 𝘰𝘧 𝘌𝘹𝘢𝘮𝘪𝘯𝘢𝘵𝘪𝘰𝘯𝘴</p>
            <p style={{ margin: "5px 0", fontSize: 16 }}>𝘜𝘕𝘐𝘝𝘌𝘙𝘚𝘐𝘛𝘠 𝘖𝘍 𝘋𝘏𝘈𝘒𝘈</p>
          </div>
        </div>
        <div className="buttons">
          <button
            style={{ backgroundColor: "#513c72", color: "white", fontSize: 20 }}
            className="btn gray"
            onClick={() => navigate('/')}
          >
            Search Again
          </button>
          <button
            style={{ backgroundColor: "#513c72", color: "white", fontSize: 20 }}
            className="btn gray"
          >
            Print
          </button>
        </div>
      </div>
    </div>
  );
};
export default ResultPage;