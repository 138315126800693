import SearchResult from "./componnet/SearchResult";
import ResultPage from "./componnet/ResultPage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
  return (
   
   <>
   <Router>
    <Routes>
      <Route path= "/" element={<SearchResult/>} />
      <Route path= "/results" element={<ResultPage/>} />
    </Routes>
   </Router>


   </>
  );
}

export default App;
